import {
  useState,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react';
import dayjs from 'dayjs';

import { getStartingDate } from './utils';

interface DateContextTypes {
  date: dayjs.Dayjs;
  setDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
}

const DateContext = createContext<DateContextTypes>({
  date: getStartingDate(),
  setDate() {},
});

export const useDate = () => useContext(DateContext);

export const DateProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [date, setDate] = useState<dayjs.Dayjs>(getStartingDate());

  return (
    <DateContext.Provider
      value={{
        date,
        setDate,
      }}
    >
      {children}
    </DateContext.Provider>
  );
};
