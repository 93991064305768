import { useMemo } from "react";

import Chart from "react-apexcharts";

import { fontFamily } from 'utils/typography';
import Card, { LabelItems, CardLayoutProps } from 'components/Card';

import { CommCoachTrend } from "../data";

type Props = CardLayoutProps & { commCoachTrend: CommCoachTrend; };

const COLORS = ['#249EFA', '#24E6A4'];

const LABELS = ['Me', 'Others'];

const MeetingParticipation = ({ className, commCoachTrend }: Props) => {
  const series = useMemo(() => {
    const { totalDuration, speakingDuration } = commCoachTrend || {};

    if (
      totalDuration === undefined
      || speakingDuration === undefined
      || speakingDuration > totalDuration
    ) {
      return [0, 0];
    }

    const speakingPercentage = Math.round(speakingDuration / totalDuration * 100);

    return [speakingPercentage, 100 - speakingPercentage];
  }, [commCoachTrend]);

  const options: ApexCharts.ApexOptions = {
    colors: COLORS,
    labels: LABELS,
    chart: {
      type: 'pie',
      height: 260,
    },
    stroke: {
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: true,
      formatter: function (value) {
        return `${value}%`;
      },
      style: {
        fontSize: '12px',
        fontFamily: fontFamily,
        fontWeight: 400,
      },
      dropShadow: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    yaxis: {
      min: 0,
      max: 100,
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <Card className={className}>
      <div className="py-5">
        <span className="px-5 font-bold text-black">
          Meeting participation
        </span>
        <div className="pt-7 flex flex-col justify-center items-center m-auto gap-3">
          <Chart
            type="pie"
            height={260}
            series={series}
            options={options}
          />
          <LabelItems
            labels={LABELS}
            colors={COLORS}
            keyPrefix="meeting-participation"
            className="px-5 flex flex-wrap justify-center gap-3"
          />
        </div>
      </div>
    </Card>
  );
};

export default MeetingParticipation;
