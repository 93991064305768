const definations = {
  happiness: (
    <p>
      Do you feel joy, satisfaction, contentment, and fulfilment? This
      feeling can positively influence your physical health and your
      relations with others.
    </p>
  ),
  anxietyFree: (
    <>
      <p>
        Anxiety is a feeling of unease, such as worry or fear. When you
        feel anxious, your body goes on high alert, looking for possible danger
        and activating your fight or flight responses.
      </p>
      <p>
        Overwhelming requirements or a poor social or familial situation can
        cause anxiety and increase your risk of panic attacks.
      </p>
      <p>
        You may feel worried, weak, tired, nervous, restless, tense, or struggling
        to concentrate.
      </p>
    </>
  ),
  stressFree: (
    <>
      <p>
        How well do you handle pressure and stress? A certain amount
        of stress is essential for achieving what we want and enjoying many
        activities; however, past a certain point, stress has psychological and
        physical effects which prevent us from performing functionally in both
        work and personal lives. In extreme cases, it results in ill health.
      </p>
      <p>
        Many develop coping mechanisms, but not all are healthy, and it's essential to
        assess how stressed you are so that you cope well. By developing
        adequate coping mechanisms, you can handle pressure calmly and
        effectively, even with tight deadlines, clashing priorities, and large
        numbers of people clamoring for your attention and urgent decisions. This
        will also reflect in your private life. However, ensure you can distinguish
        between managing your stress effectively and letting it accumulate inside
        you.
      </p>
    </>
  ),
  angerFree: (
    <>
      <p>
        You may display a strong feeling of annoyance, displeasure, or
        hostility toward someone or toward a situation. Feelings of anger arise
        from how we interpret and react to certain situations. Everyone has their
        own triggers for what makes them angry, but some common ones include
        situations in which we feel threatened, attacked, disrespected, frustrated,
        or powerless.
      </p>
      <p>
        Anger can be a good thing: it can give you a way to express negative
        feelings, for example, or motivate you to find solutions to problems. But
        excessive anger can be harmful to you and others: you may feel irritable
        and find yourself hurting others with your words.
      </p>
    </>
  ),
};

export default definations;
