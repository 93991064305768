import {
  useState,
  useEffect,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import { loginPopUpConfig } from 'utils/consent';
import { Tokens, authClient } from 'utils/tokens';
import { getSubscriptionStatus } from 'utils/subscription';
import { AppContext, getSSOToken, getAppContext } from 'utils/teams';

interface Teams {
  context: AppContext;
}

interface TeamsContextTypes {
  teams: Teams | null;
  initTokens: Tokens;
  hasInitError: boolean;
  hasInitConsent: boolean;
  hasSubscription: boolean;
}

const TeamsContext = createContext<TeamsContextTypes>({
  teams: null,
  initTokens: new Tokens(),
  hasInitError: false,
  hasInitConsent: false,
  hasSubscription: false,
});

export const useTeams = () => useContext(TeamsContext);

export const TeamsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [teams, setTeams] = useState<TeamsContextTypes["teams"]>(null);
  const [initTokens, setInitTokens] = useState<TeamsContextTypes["initTokens"]>(new Tokens());
  const [hasInitError, setHasInitError] = useState<TeamsContextTypes["hasInitError"]>(false);
  const [hasInitConsent, setHasInitConsent] = useState<TeamsContextTypes["hasInitConsent"]>(false);
  const [hasSubscription, setHasSubscription] = useState<TeamsContextTypes["hasSubscription"]>(false);

  useEffect(() => {
    setTeams(null);

    (async () => {
      try {
        const context = await getAppContext();

        const ssoToken = await getSSOToken();

        if (ssoToken) {
          const tokens = new Tokens();

          const code = searchParams.get('code');

          if (code) {
            await authClient.code(tokens, code, loginPopUpConfig);
          }

          if (!tokens.isValid()) {
            await authClient.behalfof(tokens, ssoToken, loginPopUpConfig)
          }

          const { accessToken, refreshToken } = tokens;

          if (accessToken && refreshToken) {  
            const { hasLicence, hasConsented } = await getSubscriptionStatus(
              context?.user?.id,
              context?.user?.tenant?.id,
              ssoToken,
              accessToken,
              refreshToken,
            );

            setHasInitConsent(hasConsented);
            setHasSubscription(hasLicence);
            setInitTokens(tokens);
          }
        }

        setTeams({ context });
      } catch (err) {
        const teamsExitEarlyMessage = 'Initialization Failed. No Parent window found.';

        if (
          err instanceof Error
          && err.message !== teamsExitEarlyMessage
        ) {
          Sentry.captureException(err);
        }

        setTeams(null);
        setHasInitError(true);
      }
    })()
  }, [searchParams]);

  return (
    <TeamsContext.Provider
      value={{
        teams,
        initTokens,
        hasInitError,
        hasInitConsent,
        hasSubscription,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};
