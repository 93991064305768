import { Fragment } from "react";

interface LabelItemProps {
  label: string;
  backgroundColor: string;
}

const LabelItem = ({
  label,
  backgroundColor,
}: LabelItemProps) => {
  return (
    <div className="flex items-center gap-1.5">
      <div
        style={{
          backgroundColor,
          width: '8px',
          height: '8px',
        }}
      />
      <span
        className="text-xs"
        style={{
          color: '#252424',
        }}
      >
        {label}
      </span>
    </div>
  );
};

interface LabelItemsProps {
  labels: string[];
  colors: string[];
  keyPrefix: string;
  className: string;
}

const LabelItems = ({
  labels,
  colors,
  keyPrefix,
  className,
}: LabelItemsProps) => {
  return (
    <div className={className}>
      {labels.map((label, index) =>
        <Fragment key={`${keyPrefix}-key-${index}`}>
          <LabelItem
            label={label}
            backgroundColor={colors[index]}
          />
        </Fragment>
      )}
    </div>
  );
};

export default LabelItems;
