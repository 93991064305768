import './style.css';

import arrowDownRight from 'assets/images/arrow-down-white.svg';

const TrendArrow = ({
    style,
    isRight,
    className,
    dateDelta,
    isDisabled,
    setDateDelta,
  }:
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > & {
      isRight?: boolean;
      dateDelta: number;
      isDisabled: boolean;
      setDateDelta: React.Dispatch<React.SetStateAction<number>>;
    }
  ) => {
    return (
      <button
        className={`
          ${className} justify-center items-center
          ${isRight ? 'arrow-down__right' : 'arrow-down__left'
        }`}
        style={{
          width: '32px',
          height: '32px',
          backgroundColor: `rgba(37, 36, 35, ${isDisabled ? '0.25' : '0.65'})`,
          ...(style || {}),
        }}
        onClick={() => {  
          if (!isRight) {
            setDateDelta(dateDelta + 1);
          } else if (dateDelta > 0) {
            setDateDelta(dateDelta - 1);
          }
        }}
        disabled={isDisabled}
      >
        <img
          width={16}
          height={16}
          src={arrowDownRight}
          alt="some alt"
        />
      </button>
    );
  };
  
  export default TrendArrow;
  