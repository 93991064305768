import { Mail16Regular } from '@fluentui/react-icons';

import logoBlackText from 'assets/images/logo-black-text.webp';

const Error = () => {
  return (
    <div
      className="pt-10 flex flex-col items-center gap-10 bg-background text-center"
      style={{ height: "100vh" }}
    >
      <img
        width={120}
        height={120}
        src={logoBlackText}
        alt="Virtuosis logo"
      />
      <p>
        We're sorry, an unexpected error occured!
      </p>
      <p className="inline">
        <span>
          Please contact&nbsp;
        </span>
        <a
          className="text-teamsPurple"
          href="mailto:support@virtuosis.ai"
          target="_blank"
          rel="noreferrer"
        >
          <Mail16Regular className="-mt-0.5"/>
          <span>
            support@virtuosis.ai
          </span>
        </a>
        <span>
          &nbsp;to report. We appreciate your help!
        </span>
      </p>
    </div>
  );
};

export default Error;
