const bodyScrollBarWidth = () => window.innerWidth - document.documentElement.clientWidth;

export const hideBodyScrollBar = () => {
  const padding = `0 ${bodyScrollBarWidth()}px 0 0`;
  document.body.style.overflow = 'hidden';
  document.body.style.padding = padding;
};

export const showBodyScrollBar = () => {
  document.body.style.overflow = 'visible';
  document.body.style.padding = '0px';
};
