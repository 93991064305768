import { virtuosisInsance } from 'utils/axios';

export const getAccount = async (ssoToken: string) => {
  const { data, status } = await virtuosisInsance.get(
    '/user-management/v1.0/account',
    {
      headers: { Authorization: ssoToken },
    },
  );

  if (status !== 200) {
    throw new Error(`/account call failed with status code ${status}`);
  }

  return data || {};
};
