interface LabelItemProps {
  label: string;
  value: number | undefined;
  backgroundColor: string;
}

const LabelItem = ({
  label,
  value,
  backgroundColor,
}: LabelItemProps) => {
  return (
    <li className="flex items-center gap-1 text-xs">
      <div
        style={{
          marginRight: '4px',
          backgroundColor,
          width: '8px',
          height: '8px',
        }}
      />
      <span>
        {label}:
      </span>
      <span className="font-bold">
        {value !== undefined && `${value}%`}
      </span>
    </li>
  );
};

export default LabelItem;
