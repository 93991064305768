import {
  useState,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react';
import FocusTrap from 'focus-trap-react';
import { Dismiss24Regular } from '@fluentui/react-icons';

import { showBodyScrollBar, hideBodyScrollBar } from './utils';

type ModalWrapperType = React.FunctionComponent<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    title?: string;
    childrenClassName?: string;
  }
>;

interface ModalContextTypes {
  modal: JSX.Element;
  openModal: (modal: JSX.Element) => void;
  closeModal: () => void;
  ModalWrapper: ModalWrapperType;
}

const ModalContext = createContext<ModalContextTypes>({
  modal: <></>,
  openModal: () => {},
  closeModal: () => {},
  ModalWrapper: () => <></>,
});

export const useModal = () => useContext(ModalContext);

export const ModalProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [modal, setModal] = useState<ModalContextTypes['modal']>(<></>);

  const openModal = useCallback((modal: ModalContextTypes['modal'])  => {
    hideBodyScrollBar();
    setModal(modal);
  }, []);

  const closeModal = useCallback(() => {
    showBodyScrollBar();
    setModal(<></>);
  }, []);

  const ModalWrapper: ModalWrapperType = useCallback(({
    style,
    title,
    childrenClassName,
    children: wrapperChildren,
    ...rest
  }) => {
    return (
      <FocusTrap>
        <div
          id="modalWrapper"
          className="
            z-50
            min-w-screen
            min-h-screen
            fixed
            flex
            justify-center
            inset-0
            outline-none
            bg-stone-500
            bg-opacity-80"
          style={{...style}}
          onClick={(e) => {
            const target = e.target as HTMLElement;
            if (target.id === 'modalWrapper') {
              closeModal();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              closeModal();
            }
          }}
          {...rest}
        >
          <div
            className={`
              p-5
              m-5
              relative
              my-auto
              flex
              flex-col
              justify
              gap-4
              rounded
              card-shadow
              bg-white
              ${childrenClassName || ''}
            `}
          >
            <div className="flex relative">
              <span className="font-semibold">
                {title}
              </span>
              <button
                className="absolute -right-1.5 -top-1.5"
                onClick={() => { closeModal(); }}
              >
                <Dismiss24Regular />
              </button>
            </div>
            {wrapperChildren}
          </div>
        </div>
      </FocusTrap>
    )
  }, [closeModal]);

  return (
    <ModalContext.Provider
      value={{
        modal,
        openModal,
        closeModal,
        ModalWrapper,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
