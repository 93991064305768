import { virtuosisInsance } from 'utils/axios';

export const enableAutoJoin = async (ssoToken: string, accesstoken: string, refreshToken: string) => {
  await virtuosisInsance.patch(
    '/auto-join/v1.0/enable',
    { accesstoken, refreshToken },
    {
      headers: { Authorization: ssoToken },
    },
  );
};

export const disableAutoJoin = async (ssoToken: string, accesstoken: string) => {
  await virtuosisInsance.patch(
    '/auto-join/v1.0/disable',
    { accesstoken },
    {
      headers: { Authorization: ssoToken },
    },
  );
};
