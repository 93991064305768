import { graphInsance, virtuosisInsance } from 'utils/axios';

export const NOT_SUPPORTED_MEETING_ERROR = new Error('/chats call did not return a joinWebUrl');

export const getMeeting = async (chatId: string, accessToken: string) => {
  const { data } = await graphInsance.get(
    `/v1.0/chats/${chatId}`,
    {
      headers: { Authorization: accessToken },
    },
  );

  const { onlineMeetingInfo } = data || {};

  const { organizer, joinWebUrl } = onlineMeetingInfo || {};

  const { id: organiserId } = organizer || {};

  if (!joinWebUrl || !organiserId) {
    throw NOT_SUPPORTED_MEETING_ERROR;
  }

  return {
    joinWebUrl: joinWebUrl as string,
    organiserId: organiserId as string,
  };
};

export const addBotToMeeting = async (organiserId: string, joinWebUrl: string, ssoToken: string) => {
  await virtuosisInsance.get(
    '/bot/v1.0/join',
    {
      params: { organiserId, joinurl: joinWebUrl },
      headers: { Authorization: ssoToken },
    },
  );
};
