const definations = {
  positiveness: (
    <>
      <p>
        Specific circumstances may affect your happiness for a while,
        but are you usually cheerful and content at any given time? Do you tend
        to look at the bright side of things? Do you see the glass as half full or half
        empty?
      </p>
      <p>
        When you have a positive attitude, you tend to handle stress better, be
        optimistic in life, and kind and generous with others. In addition, it can
        positively influence your relations with others; customers, colleagues, and
        friends prefer to talk to positive people.
      </p>
      <p>
        However, it is essential to recognize when it is inappropriate to show too
        much positivity, as it can harm people going through difficult times: some
        people may consider you apathetic or not taking things seriously enough.
      </p>
    </>
  ),
  clarity: (
    <p>
      Also referred to as speech intelligibility, it measures how well
      you can be understood when speaking.
    </p>
  ),
  speakingRate: (
    <p>
      The speed at which you talk. It is measured in word count per
      minute - the sweet spot is 120-150 wpm.
    </p>
  ),
  rhythm: (
    <p>
      Corresponds to the rhythm of your speech and is mainly
      affected by the pauses you make. Helps provide context, gives meaning
      to words, and keeps listeners engaged.
    </p>
  ),
};

export default definations;
