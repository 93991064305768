import { virtuosisInsance } from 'utils/axios';

import { Overall, getTrends, parseRDBData } from 'utils/data';

export type WellbeingRow = Partial<{
  startDate: string;
  happiness: number;
  angerFree: number;
  stressFree: number;
  anxietyFree: number;
  totalDuration: number;
  speakingDuration: number;
}>;

export type WellbeingTrend = WellbeingRow & Overall;

export type WellbeingTrends = Map<string, WellbeingTrend>;

export const getOverallScore = ({
  happiness,
  angerFree,
  stressFree,
  anxietyFree,
}: WellbeingRow) => {
  if (
    happiness === undefined
    || angerFree === undefined
    || stressFree === undefined
    || anxietyFree === undefined
  ) {
    return undefined;
  }

  return Math.round((happiness + angerFree + stressFree + anxietyFree) / 4);
};

export const getDefaultTrends = (startDate: string) => {
  return {
    startDate,
    overall: undefined,
    happiness: undefined,
    angerFree: undefined,
    stressFree: undefined,
    anxietyFree: undefined,
    totalDuration: undefined,
    speakingDuration: undefined,
  };
};

export const getWellbeingRows = async (ssoToken: string, abortCtrl: AbortController) => {
  const { data, status } = await virtuosisInsance.get(
    '/insights/v1.0/wellbeing',
    {
      headers: { Authorization: ssoToken },
      signal: abortCtrl?.signal,
    },
  );

  if (status !== 200) {
    throw new Error(`/wellbeing call failed with status code ${status}`);
  }

  const rows = parseRDBData(
    data,
    ['string', 'number', 'number', 'number', 'number', 'number', 'number'],
    (row: any[]): WellbeingRow => ({
      startDate: row[0],
      totalDuration: row[1],
      speakingDuration: row[2],
      happiness: row[3],
      angerFree: row[4],
      stressFree: row[5],
      anxietyFree: row[6],
    }),
  );

  return {
    rows,
    trends: getTrends(rows, getOverallScore, getDefaultTrends),
  };
};
