import Settings from './Settings';
import DateController from './DateController';

const Header = () => {
  return (
    <div
      className="px-6 py-5 md:py-0 flex flex-col md:flex-row justify-between gap-4"
      style={{
        minHeight: '44px',
        borderBottom: 'thin solid #D1D1D1',
      }}
    >
      <DateController />
      <Settings />
    </div>
  );
};

export default Header;
