import dayjs from 'dayjs';

import { Range } from './RangeBar';

export interface Tip {
  key: string;
  text: string;
}

export class Advice {
  name: string;
  range: Range;
  tips: {
    low: Tip[];
    med: Tip[];
    high: Tip[];
  };
  headers?: {
    low: string;
    med: string;
    high: string;
  };

  constructor(
    name: string,
    range: Range,
    tips: {
      low: string[];
      med: string[];
      high: string[];
    },
    headers?: {
      low: string;
      med: string;
      high: string;
    },
  ) {
    const toTips = (n: string, ts: string[]) => (
      ts.map((t, i) => ({ key: `${name}-${n}-${i+1}`, text: t }))
    );

    this.name = name;
    this.range = range;
    this.headers = headers;
    this.tips = {
      low: toTips('low', tips.low),
      med: toTips('med', tips.med),
      high: toTips('high', tips.high),
    };
  };

  advice(score: number | undefined) {
    return score === undefined ? []
      : score < this.range.med ? this.tips.low
      : score < this.range.high ? this.tips.med
      : this.tips.high;
  };

  header(score: number | undefined) {
    return score === undefined || !this.headers ? ''
      : score < this.range.med ? this.headers.low
      : score < this.range.high ? this.headers.med
      : this.headers.high;
  };
};
