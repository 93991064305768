import { useRef, useState, useEffect, useCallback } from 'react';
import * as Sentry from "@sentry/react";
import { Open16Regular } from '@fluentui/react-icons';

import { Radio } from 'components/Interactives';
import { useTeams } from 'context';
import { getSSOToken } from 'utils/teams';
import { getProcessingStatus, updateProcessingStatus } from 'utils/processing';

import {
  getMeeting,
  addBotToMeeting,
  NOT_SUPPORTED_MEETING_ERROR,
} from './utils';

import logoWhiteText from 'assets/images/logo-white-text.webp';

const MeetingPanel = () => {
  const { teams, initTokens } = useTeams();

  const toggleRef = useRef<HTMLInputElement | null>(null);

  const [errorMessage, setErrorMessage] = useState('');

  const [processingStatus, setProcessingStatus] = useState<boolean | null>(null);

  const handleToggle = useCallback(async (isChecked: boolean) => {
    if (teams && toggleRef.current) {
      try {
        toggleRef.current.disabled = true;

        const chatId = teams.context.chat?.id || '';

        const ssoToken = await getSSOToken();

        if (ssoToken) {
          await updateProcessingStatus(isChecked, ssoToken, chatId);
  
          setProcessingStatus(isChecked);
        }
      } catch (err) {
        Sentry.captureException(err);

        setErrorMessage('Could not update processing status - please try again.');
  
        toggleRef.current.checked = !isChecked;
      }
  
      toggleRef.current.disabled = false;
    }
  }, [teams]);

  const inviteBot = useCallback(async () => {
    if (teams) {
      try {
        const chatId = teams.context.chat?.id || '';

        setErrorMessage('');

        const ssoToken = await getSSOToken();

        const { accessToken } = initTokens;

        if (!ssoToken || !accessToken) {
          throw new Error('no sso or access token provided');
        }

        const { joinWebUrl, organiserId } = await getMeeting(chatId, accessToken);

        await addBotToMeeting(organiserId, joinWebUrl, ssoToken);
      } catch (err) {
        Sentry.captureException(err);

        const message = err === NOT_SUPPORTED_MEETING_ERROR
          ? "We're sorry, Virtuosis is currently not supported in calls. Please create a meeting and try again."
          : 'Something went wrong while adding Virtuosis to meeting. Please try again.';

        setErrorMessage(message);
      }
    }
  }, [teams, initTokens]);

  const initSettings = useCallback(async () => {
    if (teams) {
      try {
        setErrorMessage('');

        const userId = teams.context.user?.id || '';

        const chatId = teams.context.chat?.id || '';

        const ssoToken = await getSSOToken();

        if (ssoToken) {
          setProcessingStatus(await getProcessingStatus(userId, ssoToken, chatId));
        }
      } catch (err) {
        Sentry.captureException(err);

        setErrorMessage('Something went wrong while starting up - please try again.');
      }
    }
  }, [teams]);

  useEffect(() => {
    inviteBot();
    initSettings();
  }, [inviteBot, initSettings]);

  useEffect(() => {
    window.umami?.track((props: UmamiEvent) => ({
      ...props,
      url: '/meeting-side-panel',
      title: 'Meeting Side Panel | Virtuosis',
    }));
  }, []);

  return (
    <div
      className="
        flex flex-col px-2 py-12 gap-20 items-center overflow-x-hidden
        bg-meetingBackground text-white 
      "
      style={{ minHeight: '100vh' }}
    >
      {processingStatus !== null && (<>
        <div className="w-72 flex flex-col gap-1 items-center">
          <img
            width={120}
            height={120}
            src={logoWhiteText}
            alt="Virtuosis logo"
          />
          <span className="text-center">
            Unlock well-being and communication insights from your meetings.
          </span>
        </div>
        <div className="flex gap-3">
          <span>
            Analyse meeting
          </span>
          <Radio
            ref={toggleRef}
            onChange={(e) => { handleToggle(e.target.checked); }}
            defaultChecked={processingStatus}
          />
        </div>
        <div className="flex flex-col gap-1 items-center">
          <a
            className="flex gap-0.5 items-center text-teamsPurple"
            href="https://www.virtuosis.ai"
            rel="noreferrer"
            target="_blank"
          >
            <Open16Regular />
            <span>
              virtuosis.ai
            </span>
          </a>
          <span className="text-center">
            Encrypted, secure, and respecting your privacy.
          </span>
        </div>
      </>)}
      {errorMessage && (
        <div className="text-red-500 text-center">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default MeetingPanel;
