import { useRef, useState, useEffect } from 'react';
import * as Sentry from "@sentry/react";

import { useDate } from 'context';
import { getSSOToken } from 'utils/teams';

import Trends from './Trends';
import DailyOverview from './DailyOverview';
import MeetingDetails from './MeetingDetails';
import PersonalizedAdvice from './PersonalizedAdvice';
import MeetingParticipation from './MeetingParticipation';

import { CommCoachRow, CommCoachTrend, CommCoachTrends, getCommCoachRows } from './data';

type Props = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
};

const CommCoach = ({ setIsLoading }: Props) => {
  const { date } = useDate();

  const abortCtrl = useRef(new AbortController());

  const [selectedTrend, setSelectedTrend] = useState<CommCoachTrend>({});

  const [commCoachRows, setCommCoachRows] = useState<CommCoachRow[]>([]);

  const [commCoachTrends, setCommCoachTrends] = useState<CommCoachTrends>(new Map());

  useEffect(() => {
    (async () => {
      try {
        const ssoToken = await getSSOToken();

        if (!ssoToken) {
          throw new Error('no SSO token provided');
        }

        abortCtrl.current = new AbortController();

        const { rows, trends } = await getCommCoachRows(ssoToken, abortCtrl.current);

        setCommCoachRows(rows);

        setCommCoachTrends(trends);

        setIsLoading(false);
      } catch (err) {
        Sentry.captureException(err);

        // TODO: Create an error experience.
      }
    })()

    return () => { abortCtrl.current.abort(); };
  }, [setIsLoading]);

  useEffect(() => {
    const hasRows = commCoachRows?.length > 0;
    const hasTrends = commCoachTrends?.size > 0;

    if (hasRows && hasTrends && date.isValid()) {
      const dateFormatted = date.format('YYYY-MM-DD');

      const trend = commCoachTrends.get(dateFormatted);

      if (trend) {
        // A trend may have filled in data, so we need to check if the raw data is present.
        const row = commCoachRows.find(({ startDate }) => {
          return startDate === dateFormatted;
        });

        if (row) {
          setSelectedTrend(trend);

          return;
        }
      }
      
      setSelectedTrend({});
    }
  }, [date, commCoachRows, commCoachTrends]);

  useEffect(() => {
    window.umami?.track((props: UmamiEvent) => ({
      ...props,
      url: '/communication-coach',
      title: 'Communication Coach | Virtuosis',
    }));
  }, []);

  return (
    <div className="p-5 grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
      <MeetingDetails
        className="col-start-1 col-span-full hidden"
      />
      <DailyOverview
        className="col-start-1 col-end-2"
        commCoachTrend={selectedTrend}
      />
      <PersonalizedAdvice
        className="col-start-1 col-span-full lg:col-start-2 lg:col-end-4"
        commCoachTrend={selectedTrend}
      />
      <MeetingParticipation
        className="col-start-1 col-end-2 md:col-start-2 md:col-end-3 md:row-start-1 lg:col-start-4 lg:col-end-5"
        commCoachTrend={selectedTrend}
      />
      <Trends
        className="col-start-1 col-span-full"
        commCoachTrends={commCoachTrends}
      />
    </div>
  );
};

export default CommCoach;
