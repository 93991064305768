import { useRef } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import isTomorrow from 'dayjs/plugin/isTomorrow';

import { useWindowClick } from 'hooks';
import {
  useDate,
  getMaxDate,
  isStartingDate,
  getStartingDate,
  getFormattedDate,
} from 'context';

import arrowLeft from 'assets/images/arrow-left.svg';
import arrowDown from 'assets/images/arrow-down.svg';
import arrowRight from 'assets/images/arrow-right.svg';
import calendarIcon from 'assets/images/calendar-icon.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

dayjs.extend(isTomorrow)

const Calendar = () => {
  const { date, setDate } = useDate();

  const datePickerRef = useRef(null);
  
  const [isOpen, setIsOpen] = useWindowClick({ elementRef: datePickerRef, classTarget: 'datepicker' });

  return (
    <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
      <div className="flex">
        <button
          className="flex items-center"
          onClick={() => { setDate(getStartingDate()); }}
        >
          <img
            width={14}
            height={15}
            src={calendarIcon}
            alt="Icon to open calendar"
          />
          <span
            className="pl-2"
            style={{ color: '#616161' }}  
          >
            Today
          </span>
        </button>
        <button
          className="ml-4"
          onClick={() => { setDate(date.subtract(1, 'day')); }}
        >
          <img
            width={16}
            height={14}
            src={arrowLeft}
            alt="Arrow icon to decrease day"
          />
        </button>
        <button
          className={`ml-4 ${isStartingDate(date) ? 'opacity-20 cursor-auto' : ''}`}
          onClick={() => { 
            const nextDate = date.add(1, 'day');

            if (!nextDate.isTomorrow()) {
              setDate(date.add(1, 'day'));
            }
          }}
        >
          <img
            width={16}
            height={14}
            src={arrowRight}
            alt="Arrow icon to increase day"
          />
        </button>
      </div>
      <div ref={datePickerRef}>
        <button
          className="flex items-center gap-3"
          onClick={() => { setIsOpen(!isOpen); }}
        >
          <span
            className="font-bold"
            style={{ color: isOpen ? '#5B5FC7' : '#242424' }}  
          >
            {getFormattedDate(date)}
          </span>
          <img
            width={14}
            height={7}
            src={arrowDown}
            alt="Arrow Down Icon"
          />
        </button>
        {isOpen && (
          <div className="z-50 mt-1 absolute">
            <DatePicker
              inline
              calendarStartDay={1}
              calendarClassName="tab-calendar"
              selected={date.toDate()}
              maxDate={getMaxDate().toDate()}
              // dayClassName={(date) => { return null; }}
              // highlightDates={[]}
              onChange={(date) => {
                if (date) {
                  setDate(dayjs(date));
                  setIsOpen(!isOpen);
                }
              }}
              renderCustomHeader={({
                date: headerDate,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  className="px-4 flex items-center justify-between"
                  style={{
                    height: '40px',
                    backgroundColor: "#F3F2F1",
                  }}
                >
                  <span className="font-bold">
                    {dayjs(headerDate).format('MMMM YYYY')}
                  </span>
                  <div className="flex items-center gap-5">
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      <img
                        width={14}
                        height={7}
                        className={`${prevMonthButtonDisabled ? 'opacity-20' : ''}`}
                        style={{ transform: 'rotate(90deg)' }}
                        src={arrowDown}
                        alt="Arrow icon to decrease month"
                      />
                    </button>
                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      <img
                        width={14}
                        height={7}
                        className={`${nextMonthButtonDisabled ? 'opacity-20' : ''}`}
                        style={{ transform: 'rotate(270deg)' }}
                        src={arrowDown}
                        alt="Arrow icon to increase month"
                      />
                    </button>
                  </div>
                </div>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Calendar;
