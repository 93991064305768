import { useState, useEffect } from 'react';

interface useWindowClickProps {
  elementRef?: React.MutableRefObject<HTMLDivElement | null>;
  classTarget?: string;
};

const useWindowClick = ({
  elementRef,
  classTarget,
}: useWindowClickProps): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    const pageClick = (e: any): void => {
      const target = e?.target || null;

      const containsTargetNode = elementRef?.current?.contains?.(target);

      const containsTargetClass = classTarget && target?.className?.includes?.(classTarget);

      if (!containsTargetNode && !containsTargetClass) {
        setIsActive(false);
      }
    };

    if (isActive) {
      window.addEventListener('click', pageClick);
    }

    return () => window.removeEventListener('click', pageClick);
  }, [isActive, classTarget, elementRef]);

  return [isActive, setIsActive];
};

export default useWindowClick;
