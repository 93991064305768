import { useEffect, useState } from 'react';

import Card, { AdviceItem, CardLayoutProps } from 'components/Card';

import { WellbeingRow } from '../data';

import advice from './advice';
import definations from './definations';

type Props = CardLayoutProps & { wellbeingRow: WellbeingRow; };

const PersonalizedAdvice = ({ className, wellbeingRow }: Props) => {
  const [activePosition, setActivePosition] = useState<number | null>(null);

  useEffect(() => { setActivePosition(null); }, [wellbeingRow]);

  return (
    <Card className={className}>
      <div
        className="p-2 xxs:p-5 flex flex-col"
        style={{ minHeight: '338px' }}
      >
        <span className="font-bold text-black">
          Personalized advice
        </span>
        <div className="pt-7 xs:pl-2.5 lg:px-0 xl:px-2.5 flex flex-col gap-5">
          <AdviceItem
            title="Stress-free"
            position={0}
            activePosition={activePosition}
            setActivePosition={setActivePosition}
            backgroundColor="#249EFA"
            score={wellbeingRow?.stressFree}
            advice={advice.stressFree.advice(wellbeingRow?.stressFree)}
            definition={definations.stressFree}
          />
          <AdviceItem
            title="Anxiety-free"
            position={1}
            activePosition={activePosition}
            setActivePosition={setActivePosition}
            backgroundColor="#24E6A4"
            score={wellbeingRow?.anxietyFree}
            advice={advice.anxietyFree.advice(wellbeingRow?.anxietyFree)}
            definition={definations.anxietyFree}
          />
          <AdviceItem
            title="Happiness"
            position={2}
            activePosition={activePosition}
            setActivePosition={setActivePosition}
            backgroundColor="#FDBA3A"
            score={wellbeingRow?.happiness}
            advice={advice.happiness.advice(wellbeingRow?.happiness)}
            definition={definations.happiness}
          />
          <AdviceItem
            title="Anger-free"
            position={3}
            activePosition={activePosition}
            setActivePosition={setActivePosition}
            backgroundColor="#FD5F76"
            score={wellbeingRow?.angerFree}
            advice={advice.angerFree.advice(wellbeingRow?.angerFree)}
            definition={definations.angerFree}
          />
        </div>
      </div>
    </Card>
  );
};

export default PersonalizedAdvice;
