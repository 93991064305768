import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';

dayjs.extend(dayOfYear);
dayjs.extend(isLeapYear);

const getDates = (date: dayjs.Dayjs, numOfDays: number) => {
  return [...new Array(numOfDays)].map((_, i) => (
    date.subtract(numOfDays - i - 1, 'day').format('YYYY-MM-DD')
  ));
};

export const getMonthsInYear = () => (
  [...new Array(12)].map((_, i) => i < 9 ? `0${i+1}` : `${i+1}`)
);

export const getWeekDates = (dateDelta: number) => {
  const date = dayjs().subtract(dateDelta, 'week');

  return getDates(
    date.get('day') === 0 ? date : date.set('day', 0).add(1, 'week'),
    7,
  );
};

export const getMonthDates = (monthDelta: number) => {
  const date = dayjs().subtract(monthDelta, 'month').endOf('month');

  return getDates(date, date.daysInMonth());
};

export const getYearDates = (yearDelta: number) => {
  const date = dayjs().subtract(yearDelta, 'year').endOf('year');

  return getDates(date, date.isLeapYear() ? 366 : 365);
};

export const getYearMonths = (yearDelta: number) => {
  const year = dayjs().subtract(yearDelta, 'year').year();

  return getMonthsInYear().map((m) => `${year}-${m}-01`);
};
