import axios, { AxiosRequestConfig } from 'axios';

import { PopUpConfig } from 'utils/consent';

export const INTERACTION_REQUIRED_TEXT = 'INTERACTION_REQUIRED_TEXT';

export const INTERACTION_ERRORS = [
  'invalid_grant',
  'interaction_required',
];

export class Tokens {
  accessToken: string | null;
  refreshToken: string | null;

  constructor(accessToken: string | null = null, refreshToken: string | null = null) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  isValid() {
    return !!this.accessToken && !!this.refreshToken;
  }
}

class AuthClient {

  async #fetch (config: AxiosRequestConfig) {
    const { data } = await axios(config);

    const {
      error,
      access_token: accessToken,
      refresh_token: refreshToken,
    } = data || {};

    if (error && INTERACTION_ERRORS.includes(error.toLowerCase())) {
      return { accessToken: null, refreshToken: null};
    }

    if (error) {
      throw new Error(`unexpected error: ${error}`);
    }

    if (!accessToken || typeof accessToken != 'string') {
      throw new Error('unexpectedly missing access token or is unexpected type');
    }

    if (!refreshToken || typeof refreshToken != 'string') {
      throw new Error('unexpectedly missing refresh token or is unexpected type');
    }

    return { accessToken, refreshToken };
  };

  async code (
    tokens: Tokens,
    code: string,
    popUpConfig: PopUpConfig,
    abortSignal?: AbortSignal,
  ) {
    const { scopes, redirectUri } = popUpConfig || {};

    const { accessToken, refreshToken } = await this.#fetch({
      method: 'GET',
      url: 'https://virtuosis-client-prod.azure-api.net/auth/v1.0/code',
      params: { code, redirectUri, scopes: scopes.join(' ') },
      signal: abortSignal,
    });

    tokens.accessToken = accessToken;
    tokens.refreshToken = refreshToken;

    return tokens.isValid();
  };

  async behalfof(
    tokens: Tokens,
    ssoToken: string,
    popUpConfig: PopUpConfig,
    abortSignal?: AbortSignal,
  ) {
    const { scopes } = popUpConfig || {};

    const { accessToken, refreshToken } = await this.#fetch({
      method: 'GET',
      url: 'https://virtuosis-client-prod.azure-api.net/auth/v1.0/behalfof',
      params: { ssoToken, scopes: scopes.join(' ') },
      signal: abortSignal,
    });

    tokens.accessToken = accessToken;
    tokens.refreshToken = refreshToken;

    return tokens.isValid();
  }
}

export const authClient = new AuthClient();
