import { useTeams } from 'context';

import PersonalTab from 'components/PersonalTab';
import MeetingPanel from 'components/MeetingPanel';
import { Consent, Starting, NoSubscription } from 'components/Init';

const App = () => {
  const { teams, hasInitConsent, hasSubscription } = useTeams();

  const isMeetingPanel = teams?.context?.page?.id === 'meeting.side.panel.configurable.tab';

  if (!teams) {
    return <Starting />;
  }

  if (!hasInitConsent) {
    return <Consent />;
  }

  if (!hasSubscription) {
    return <NoSubscription />;
  }

  if (isMeetingPanel) {
    return <MeetingPanel />;
  }

  return <PersonalTab />
};

export default App;
