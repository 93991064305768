export enum TrendDateRange {
  Year = 0,
  Month = 1,
  Week = 2,
}

interface TrendDatesProps {
  titles: string[];
  keyPrefix: string;
  className: string;
  activePosition: TrendDateRange;
  setActivePosition: React.Dispatch<React.SetStateAction<TrendDateRange>>;
}

const TrendDates = ({
  titles,
  keyPrefix,
  className,
  activePosition,
  setActivePosition,
}: TrendDatesProps) => {
  return (
    <div className={className}>
      {titles.map((title, index) => (
        <button
          key={`${keyPrefix}-key-${index}`}
          className="grid"
          onClick={() => {
            setActivePosition(index);

            window.umami?.track((props: UmamiEvent) => ({
              ...props,
              name: `${keyPrefix}-${title.toLowerCase()}-button`,
            }));
          }}
        >
          <span style={{ color: '#605E5C' }}>
            {title}
          </span>
          <div
            style={{
              display: activePosition === index ? 'block' : 'none',
              height: '3px',
              backgroundColor: '#5B5FC7'
            }}
          />
        </button>
      ))}
    </div>
  );
};

export default TrendDates;
