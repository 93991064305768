import * as Sentry from "@sentry/react";
import { app, authentication } from '@microsoft/teams-js';

import { PopUpConfig } from 'utils/consent';

export type AppContext = Partial<app.Context>;

export const getAppContext = async () => {
  if (process.env.NODE_ENV !== 'production') {
    const mockedContext: AppContext = {
      chat: { id: process.env.REACT_APP_TEAMS_CONTEXT_USER_ID || '' },
      page: { id: process.env.REACT_APP_TEAMS_CONTEXT_PAGE_ID || '' } as app.PageInfo,
      user: {
        id: process.env.REACT_APP_TEAMS_CONTEXT_USER_ID || '',
        tenant: { id: process.env.REACT_APP_TEAMS_CONTEXT_USER_TENENT_ID || '' },
      },
    };

    return mockedContext;
  }

  await app.initialize();

  return await app.getContext();
};

export const getSSOToken = async () => {
  try {
    if (process.env.NODE_ENV !== 'production') {
      const mockedSSOToken = process.env.REACT_APP_TEAMS_SSO_TOKEN || null;

      return mockedSSOToken;
    }

    const authTokenRequest: authentication.AuthTokenRequestParameters = ({
      silent: true,
      resources: ['api://teams.virtuosis.ai/8184def4-9134-4920-bf82-05e31fa44469'],
    });

    return await authentication.getAuthToken(authTokenRequest);
  } catch (err) {
    const requiresConsentText = 'resourceRequiresConsent';
    const interactionRequiredText = 'errorCode: "InteractionRequired"';

    if (
      err instanceof Error
      && err.message !== requiresConsentText
      && !err.message?.includes(interactionRequiredText)
    ) {
      Sentry.captureException(err);
    }

    return null;
  }
};

export const launchConsentPopup = async (
  popUpConfig: PopUpConfig,
  width: number = 750,
  height: number = 650,
) => {
  const { url } = popUpConfig || {};

  const popUpParams: authentication.AuthenticatePopUpParameters = { url, width, height };

  return await authentication.authenticate(popUpParams);
};
