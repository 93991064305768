import { forwardRef } from 'react';

const Radio = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>((
  {
    onChange,
    defaultChecked,
  },
  ref,
) => {
  return (
    <label className="inline-flex relative cursor-pointer">
      <input
        ref={ref}
        value=""
        type="checkbox"
        className="sr-only peer"
        onChange={onChange}
        defaultChecked={defaultChecked}
      />
      <div
        className="w-10 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-teamsPurple"
      />
    </label>
  )
});

export default Radio;
