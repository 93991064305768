const Button: React.FunctionComponent<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    icon?: JSX.Element;
    title?: string;
  }
> = ({
  icon,
  style,
  title,
  children,
  ...rest
}) => {

  return (
    <button
      type="button"
      className="
        py-2
        px-3
        flex
        gap-2
        items-center
        rounded
        text-white
        font-semibold
        bg-teamsPurple
        disabled:opacity-75"
      style={{ ...(style || {}) }}
      {...rest}
    >
      {icon}
      {title ?? children}
    </button>
  );
};

export default Button;
