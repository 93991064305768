import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { getYearDates, getMonthsInYear } from './dates';

dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

export type Overall = Partial<{ overall: number; }>;

type Trends = Record<string, number | string | undefined>;

export const DATE_FORMAT = 'YYYY-MM-DD';

export const CURRENT_DATE = dayjs();

export const parseRDBData = <T>(
  data: any,
  types: (number | string)[],
  parser: (row: any[]) => T,
) => {
  if (!Array.isArray(data)) {
    throw new Error('data is not an array');
  }

  const isRowValid = (row: any) => (
    Array.isArray(row)
    && row.length === types.length
    && row.every((record, i) => typeof(record) === types[i])
  );

  data.forEach((row, i) => {
    if (!isRowValid(row)) {
      throw new Error(`failed to validate row at index ${i}`);
    }
  });

  return data.map<T>((row) => parser(row));
};

export const getTrends = <T extends Trends>(
  rows: T[],
  getOverallScore: (arg: T) => number | undefined,
  getDefaultTrends: (startDate: string) => T & Overall,
) => {
  const trends: Map<string, T> = new Map();

  const firstRow = rows.at(0);

  const lastRow = rows.at(-1);

  if (!firstRow || !lastRow) {
    return trends;
  }

  const firstDate = dayjs(firstRow.startDate, DATE_FORMAT);

  const lastDate = dayjs(lastRow.startDate, DATE_FORMAT);

  if (
    !firstDate.isValid()
    || !lastDate.isValid()
    || firstDate.isAfter(lastDate, 'date')
  ) {
    return trends;
  }

  const rowsMap = new Map(rows.map((r) => [r.startDate || '', r]))

  const loopCTX: {
    date: dayjs.Dayjs;
    trend: T & Overall;
    count: number;
  } = {
    date: firstDate,
    trend: {...firstRow, overall: getOverallScore(firstRow)},
    count: 0,
  };

  while (
    loopCTX.date.isSameOrBefore(lastDate, 'date')
    && loopCTX.count < 10957 // 30 years in days.
  ) {
    const dateFormatted = loopCTX.date.format(DATE_FORMAT);

    const matchedRow = rowsMap.get(dateFormatted);

    const trend = matchedRow
      ? {...matchedRow, overall: getOverallScore(matchedRow), startDate: dateFormatted}
      : getDefaultTrends(dateFormatted);

    trends.set(dateFormatted, trend);

    loopCTX.trend = trend;

    loopCTX.date = loopCTX.date.add(1, 'day');

    loopCTX.count += 1;
  }

  return trends;
};

export const getYearTrends = <T extends Trends>(
  trendKeys: string[],
  data: Map<string, T>,
) => {
  const aggTrends = new Map<string, T>();

  if (data.size <= 0) {
    return aggTrends;
  }

  const monthsInYear = getMonthsInYear();

  const [firstDate] = [...data][0] || [];

  const yearDiff = CURRENT_DATE.year() - dayjs(firstDate).year();

  [...new Array(yearDiff+1)].forEach((_, yearDelta) => {
    const yearDates = getYearDates(yearDelta);

    const monthlyTrends = new Map(
      monthsInYear.map<[string, Trends[]]>((m) => [m, []])
    );
  
    yearDates.forEach((date) => {
      const trends = data.get(date);
  
      if (trends?.totalDuration) {
        const [, month] = date.match(/\d{4}-(\d{2})-\d{2}/) || [];
  
        if (month) {
          monthlyTrends.set(month, [...(monthlyTrends.get(month) || []), trends]);
        }
      }
    });
  
    monthsInYear.forEach((month) => {
      const trends = monthlyTrends.get(month);
  
      const startDate = `${CURRENT_DATE.get('year')-yearDelta}-${month}-01`;
  
      if (trends?.length) {
        const aggTrend: Trends = { startDate };
  
        trendKeys.forEach((key) => {
          if (key !== 'startDate') {
            const sum = trends.reduce((acc, { [key]: val } = {}) => (
              typeof (val) === 'number' ? val + acc : acc
            ), 0);
  
            aggTrend[key] = Math.round(sum / trends.length);
          }
        });

        aggTrends.set(startDate, aggTrend as T);
      }
    });
  });

  return aggTrends;
};
