import { Open16Regular } from '@fluentui/react-icons';
import { HostClientType } from '@microsoft/teams-js';

import { useTeams } from 'context';

import { Button } from 'components/Interactives';

import logoWhiteText from 'assets/images/logo-white-text.webp';
import logoBlackText from 'assets/images/logo-black-text.webp';

const NoSubscription = () => {
  const { teams } = useTeams();

  const isMeetingPanel = teams?.context?.page?.id === 'meeting.side.panel.configurable.tab';

  const clientType = teams?.context?.app?.host?.clientType;

  const isMobile = clientType === HostClientType.ios
    || clientType === HostClientType.ipados
    || clientType === HostClientType.android
    || clientType === HostClientType.teamsPhones
    || clientType === HostClientType.teamsRoomsAndroid;

  return (
    <div 
      className={`
        grid justify-center py-12
        ${isMeetingPanel ? 'bg-meetingBackground text-white' : 'bg-background'}
      `}
      style={{ minHeight: '100vh' }}
    >
      <div
        className={`
          ${isMeetingPanel ? 'w-full' : 'w-72'}
          flex flex-col items-center gap-8
          text-center
        `}>
        <div className="flex flex-col gap-1 items-center">
          <img
            width={120}
            height={120}
            src={isMeetingPanel ? logoWhiteText : logoBlackText}
            alt="Virtuosis logo"
          />
          <p>
            Unlock well-being and communication insights from your meetings.
          </p>
        </div>
        <p className="mt-6">
          Oops! We could not find your licence.
          {!isMobile && " Check out our offers to activate a subscription."}
        </p>
        {!isMobile && (
          <Button
            icon={<Open16Regular />}
            title="Subscription Offers"
            onClick={() => {
              window.open(
                'https://appsource.microsoft.com/en-us/product/web-apps/virtuosisartificialintelligencesa1654721484637.virtuosis_test_offer',
                '_blank',
              );
            }}
          />
        )}
        <p>
          {isMobile
            ? "Please contact us if you would like to learn more about Virtuosis."
            : "Please contact us if you would like to request a free trial of Virtuosis."}
        </p>
        <Button
          icon={<Open16Regular />}
          title="Contact Us"
          onClick={() => {
            window.open(
              'https://www.virtuosis.ai/contact',
              '_blank',
            );
          }}
        />
        <p>
          If your organisation has a subscription but you have not been assigned a licence,
          please visit Teams subscription management to be assigned a licence.
        </p>
        <Button
            icon={<Open16Regular />}
            title="Subscription Management"
            onClick={() => {
              window.open(
                'https://aka.ms/teamsapplicensemanagement',
                '_blank',
              );
            }}
          />
      </div>
    </div>
  );
};

export default NoSubscription;
