import axios, { AxiosError } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';

export const graphInsance = axios.create({
    baseURL: 'https://graph.microsoft.com',
});

export const virtuosisInsance = axios.create({
    baseURL: 'https://virtuosis-client-prod.azure-api.net',
});

const retryConfig: IAxiosRetryConfig = {
    retries: 5,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error: AxiosError) => {
        const status = error?.response?.status;

        if (status === undefined) {
            return true;
        }

        const isRateLimit = status === 429;

        const isServerError = status >= 500 && status < 600;

        return isRateLimit || isServerError;
    },
};

axiosRetry(graphInsance, retryConfig);

axiosRetry(virtuosisInsance, retryConfig);
