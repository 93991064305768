import { useState } from 'react';

import { useTeams, useModal } from 'context';

import Header from 'components/Header';
import Wellbeing from 'components/Wellbeing';
import CommCoach from 'components/CommCoach';

const PersonalTab = () => {
  const { teams } = useTeams();

  const { modal } = useModal();

  const [, setIsLoading] = useState(true);

  const isWellBeingTab = teams?.context?.page?.id === 'well.being.personal.tab';

  const isCommCoachTab = teams?.context?.page?.id === 'communication.coach.personal.tab';

  if (!isWellBeingTab && !isCommCoachTab) {
    return (
      <div className="bg-background">
        Page not supported.
      </div>
    );
  }

  return (
    <div className="grid bg-background">
      {modal}
      <Header />
      {isWellBeingTab
        ? <Wellbeing setIsLoading={setIsLoading} />
        : <CommCoach setIsLoading={setIsLoading} />}
    </div>
  );
};

export default PersonalTab;
