import { useEffect, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  useLocation,
  matchRoutes,
  RouterProvider,
  useNavigationType,
  createBrowserRouter,
  createRoutesFromChildren,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

import { TeamsProvider, DateProvider, ModalProvider } from 'context';

import App, { Error } from 'components';

import 'assets/css/styles.css';

Sentry.init({
  dsn: "https://bfbcb996ac4b7ff381621592b48573ec@o4506237862412288.ingest.sentry.io/4506237884825600",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.NODE_ENV, 
});

declare global {

  interface UmamiEvent {
    hostname: string; // Name of host.
    language: string; // Language of visitor (ex. "en-US")
    referrer: string; // Referrer URL.
    screen: string; // Screen resolution (ex. "1920x1080")
    title: string; // Page title.
    url: string; // Page URL.
    website: string; // Website ID.
    name?: string; // Name of the event.
    data?: object; // Additional data for the event.
  }

  interface Umami {
    track(props: any): UmamiEvent;
  }

  var umami: Umami | undefined;

}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: (
      <FluentProvider theme={teamsLightTheme}>
        <TeamsProvider>
          <DateProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </DateProvider>
        </TeamsProvider>
      </FluentProvider>
    ),
    errorElement: <Error />,
  },
]);

ReactDOM
  .createRoot(document.getElementById('root') as HTMLElement)
  .render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
