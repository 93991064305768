import { useSearchParams } from "react-router-dom";
import { Spinner } from '@fluentui/react-components';

import { useTeams } from 'context';

import logoWhiteText from 'assets/images/logo-white-text.webp';
import logoBlackText from 'assets/images/logo-black-text.webp';

const Starting = () => {
  const { hasInitError } = useTeams();

  const [searchParams] = useSearchParams();

  const isMeetingPanel = searchParams.get('isMeetingPanel') === 'true';

  return (
    <div
      className={`
        grid justify-center items-center overflow-hidden
        ${isMeetingPanel ? 'bg-meetingBackground text-white' : 'bg-background'}
      `}
      style={{ minHeight: '100vh' }}
    >
      <div className="-mt-24 w-80 flex flex-col items-center gap-12 text-lg text-center">
        {hasInitError ? (
          <span className="text-red-500">
            Something went wrong while starting. Please try again. 
          </span>
        ) : (
          <>
            <Spinner size="huge" />
            <span>
              {isMeetingPanel ? (
                <>Starting meeting panel!</>
              ) : (
                <>Starting your dashboard!</>
              )}
            </span>
          </>
        )}
        <img
          width={120}
          height={120}
          src={isMeetingPanel ? logoWhiteText : logoBlackText}
          alt="Virtuosis logo"
        />
      </div>
    </div>
  );
};

export default Starting;
