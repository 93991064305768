enum PopUpURL {
  Login = 'https://teams.virtuosis.ai/auth-start.html',
  AutoJoin = 'https://teams.virtuosis.ai/auto-join-auth-start.html',
}

enum PopUpRedirectUri {
  AuthEnd = 'https://teams.virtuosis.ai/auth-end.html',
}

enum PopUpScope {
  User_Read = 'https://graph.microsoft.com/User.Read',
  Chat_ReadBasic = 'https://graph.microsoft.com/Chat.ReadBasic',
  Offline_Access = 'offline_access',
  Calendars_Read = 'https://graph.microsoft.com/Calendars.Read',
  TeamsTab_Create = 'https://graph.microsoft.com/TeamsTab.Create',
  TeamsAppInstallation_ReadWriteAndConsentSelfForChat = 'https://graph.microsoft.com/TeamsAppInstallation.ReadWriteAndConsentSelfForChat',
}

export interface PopUpConfig {
  url: PopUpURL;
  scopes: PopUpScope[];
  redirectUri: PopUpRedirectUri;
}

export const loginPopUpConfig: PopUpConfig = {
  url: PopUpURL.Login,
  redirectUri: PopUpRedirectUri.AuthEnd,
  scopes: [
    PopUpScope.User_Read,
    PopUpScope.Chat_ReadBasic,
    PopUpScope.Offline_Access,
  ],
};

export const autoJoinPopUpConfig: PopUpConfig = {
  url: PopUpURL.AutoJoin,
  redirectUri: PopUpRedirectUri.AuthEnd,
  scopes: [
    PopUpScope.Calendars_Read,
    PopUpScope.Offline_Access,
    PopUpScope.TeamsTab_Create,
    PopUpScope.TeamsAppInstallation_ReadWriteAndConsentSelfForChat,
  ],
};
