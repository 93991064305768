import { useEffect, useState } from "react";

interface BarGraphProps {
  score: number | undefined;
  backgroundColor: string;
}

const BAR_WIDTH = 200;

const HorizontalBar = ({
  score,
  backgroundColor,
}: BarGraphProps) => {
  const [activeWidth, setActiveWidth] = useState(0);

  useEffect(() => {
    if (typeof score === 'number') {
      setActiveWidth(Math.floor(BAR_WIDTH * (score / 100)))
    } else {
      setActiveWidth(0)
    }
  }, [score]);

  return (
    <div
      className="h-5 w-full flex items-center sm:justify-end gap-2.5"
      style={{ maxWidth: '350px' }}
    >
      <div
        className="h-2.5 grid"
        style={{ width: BAR_WIDTH }}
      >
        <div
          className="transition-all ease-in-out duration-700"
          style={{
            backgroundColor,
            width: activeWidth,
            gridRowStart: 1,
            gridColumnStart: 1
          }}
        />
        <div
          style={{
            backgroundColor,
            opacity: 0.2,
            gridRowStart: 1,
            gridColumnStart: 1,
          }} />
      </div>
      <span className="hidden xxs:flex justify-end" style={{ width: '37px' }}>
        {score !== undefined && `${score}%`}
      </span>
    </div>
  );
};

export default HorizontalBar;
