import { useMemo } from "react";
import Chart from "react-apexcharts";

import { fontFamily } from 'utils/typography';
import Card, { LabelItems, CardLayoutProps } from 'components/Card';

import { CommCoachTrend } from "../data";

type Props = CardLayoutProps & { commCoachTrend: CommCoachTrend; };

const COLORS = ['#249EFA', '#FDBA3A', '#BB6BD9', '#24E6A4'];

const LABELS = ['Positiveness', 'Clarity', 'Speaking rate', 'Rhythm'];

const DailyOverview = ({ className, commCoachTrend }: Props) => {
  const series = useMemo(() => ([
    commCoachTrend?.positiveness || 0,
    commCoachTrend?.clarity || 0,
    commCoachTrend?.speakingRate || 0,
    commCoachTrend?.rhythm || 0,
  ]), [commCoachTrend]);

  const options: ApexCharts.ApexOptions = {
    colors: COLORS,
    labels: LABELS,
    chart: {
      type: 'polarArea',
      height: 280,
    },
    stroke: {
      colors: ['#fff'],
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: true,
      formatter: function (_, options) {
        const { w, seriesIndex } = options || {};

        const { series: s } = w?.globals || {};

        if (s.every((v: number) => v === 0)) {
          return '';
        }

        return `${(s || [])[seriesIndex ?? Infinity] || 0}%`;
      },
      style: {
        fontSize: '12px',
        fontFamily: fontFamily,
        fontWeight: 400,
      },
    },
    legend: {
      show: false,
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        formatter: (_) => '',
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <Card className={className}>
      <div className="py-5">
        <span className="px-5 font-bold text-black">
          Daily overview
        </span>
        <div className="pt-5 flex flex-col justify-center items-center m-auto gap-3">
          <Chart
            type="polarArea"
            height={280}
            series={series}
            options={options}
          />
          <span style={{ width: '190px' }}>
            Communication score: {
              commCoachTrend?.overall !== undefined && <b>{commCoachTrend.overall}%</b>
            }
          </span>
          <LabelItems
            labels={LABELS}
            colors={COLORS}
            keyPrefix="daily-overview"
            className="px-5 flex flex-wrap justify-center gap-3"
          />
        </div>
      </div>
    </Card>
  );
};

export default DailyOverview;
