import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import { Button } from 'components/Interactives';
import { loginPopUpConfig } from 'utils/consent';
import { launchConsentPopup } from 'utils/teams';

import logoWhiteText from 'assets/images/logo-white-text.webp';
import logoBlackText from 'assets/images/logo-black-text.webp';

const Consent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [hasError, setHasError] = useState(false);

  const isMeetingPanel = searchParams.get('isMeetingPanel') === 'true';

  const handleConsentPopup = useCallback(async () => {
    try {
      const consentParams = new URLSearchParams(await launchConsentPopup(loginPopUpConfig));

      const code = consentParams.get('code');

      const error = consentParams.get('error');

      if (error || !code) {
        throw new Error(error || 'no code provided');
      }

      setSearchParams((params: URLSearchParams) => {
        params.set('code', code);

        return params;
      });
    } catch (err) {
      Sentry.captureException(err);

      setHasError(true);
    }
  }, [setSearchParams]);

  return (
    <div
      className={`
        grid justify-center items-center
        ${isMeetingPanel ? 'bg-meetingBackground text-white' : 'bg-background'}
      `}
      style={{ minHeight: '100vh' }}
    >  
      <div className="lg:-mt-24 xs:w-96 flex flex-col items-center gap-12">
        {hasError && (
          <span className="text-red-500">
            Something went wrong while processing consent. Please try again or reload app...
          </span>
        )}
        <span>
          Virtuosis requires consent to access Microsoft resources on your behalf:
        </span>
        <Button
          title="Provide consent"
          onClick={() => { handleConsentPopup(); }}
        />
        <div className="flex flex-col gap-0.5">
          <span>
            Virtuosis is committed to your privacy.
          </span>
          <ul className="pl-6 list-disc grid gap-0.5">
            <li>
              Your private information stays always with you.
            </li>
            <li>
              We do not share individual insights with your manager.
            </li>
            <li>
              We do not know the content of your conversations.
            </li>
            <li>
              We do not store your audio recordings.
            </li>
            <li>
              We do not sell your information to third parties.
            </li>
          </ul>
        </div>
        <img
          width={120}
          height={120}
          src={isMeetingPanel ? logoWhiteText : logoBlackText}
          alt="Virtuosis logo"
        />
      </div>
    </div>
  );
};

export default Consent;
