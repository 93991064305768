import { virtuosisInsance } from 'utils/axios';

export const getProcessingStatus = async (userId: string, ssoToken: string, meetingId?: string) => {
  const { data, status } = await virtuosisInsance.get(
    '/user-management/v1.0/account/processing/status',
    {
      params: { MeetingId: meetingId },
      headers: { Authorization: ssoToken },
    },
  );

  if (status !== 200) {
    throw new Error(`/meeting/status call failed with status code ${status}`);
  }

  if (!Array.isArray(data)) {
    throw new Error('/meeting/status is not an array');
  }

  return data.includes(userId);
};

export const updateProcessingStatus = async (isChecked: boolean, ssoToken: string, meetingId?: string) => {
  const { status } = await virtuosisInsance.patch(
    '/user-management/v1.0/account/processing/status',
    null,
    {
      params: {
        Status: isChecked ? 'true' : 'false',
        MeetingId: meetingId,
      },
      headers: { Authorization: ssoToken },
    },
  );

  if (status !== 200) {
    throw new Error(`/processing/status call failed with status code ${status}`);
  }
};
