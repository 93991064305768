import dayjs from 'dayjs';

export const getStartingDate = () => {
  return dayjs();
};

export const getMaxDate = () => {
  return dayjs();
}

export const getFormattedDate = (date: dayjs.Dayjs) => {
  return date.format('ddd, MMMM D YYYY');;
};

export const isStartingDate = (date: dayjs.Dayjs) => {
  return getStartingDate().isSame(date, 'day');
};
